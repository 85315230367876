import React from 'react';

import { IPostCard } from '../../Card';
import { PostStats } from '../../Stats';

import style from './Trending.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export const TrendingPostCard = ({ post, index }: { post: IPostCard; index: number }) => {
  const { slug, title, published_at, primary_author } = post;
  const url = `/${slug}/`;

  return (
    <Link to={url} className={style.base}>
      <h2>0{index}</h2>
      <div>
        <div className={style.headerContent}>
          <span className={cn('h4', style.title)}>{title}</span>
        </div>
        <PostStats
          publishedAt={published_at}
          author={primary_author}
          isReadingTimeShown
          post={post}
        />
      </div>
    </Link>
  );
};
