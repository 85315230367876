import React from 'react';

import { IPostCard } from '../Card';
import { TagPostCard } from '../Card/Tag';

interface IProps {
  className?: string;
  posts: {
    node: IPostCard;
  }[];
}

export const TagPosts = ({ className, posts }: IProps) => {
  return (
    <section className={className}>
      {posts.map(({ node }, index) => (
        <TagPostCard key={index} post={node} />
      ))}
    </section>
  );
};
