import React from 'react';

import Chevron from '../../../../images/Chevron';
import { list } from '../A/Hero';

import style from './Hero.scss';

const HeroB = () => {
  return (
    <section className={style.hero}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>
              Navigate life on your terms
              <small>Compare rates and get expert guidance on all things finance</small>
            </h1>
            <div className={style.items}>
              {list.map((item) => {
                return item.subLinks ? (
                  <div className={style.hasSubLinks}>
                    {item.icon}
                    <h4>{item.title}</h4>
                    <div className={style.subItems}>
                      {item.subLinks.map((subItem) => (
                        <a
                          key={subItem.href + subItem.title}
                          href={subItem.href}
                          target={subItem.target}
                        >
                          {subItem.title}
                          <Chevron />
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <a key={item.href + item.title} href={item.href} target={item.target}>
                    {item.icon}
                    <h4>{item.title}</h4>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroB;
