import React from 'react';

import { IPostCard } from '../../Card';
import { PostStats } from '../../Stats';

import style from './Tag.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export const TagPostCard = ({ post }: { post: IPostCard }) => {
  const { slug, title, published_at, feature_image } = post;
  const url = `/${slug}/`;

  return (
    <Link to={url} className={style.base}>
      <div>
        <div className={style.headerContent}>
          <span className={cn('h4', style.title)}>{title}</span>
        </div>
        <PostStats publishedAt={published_at} isReadingTimeShown post={post} />
      </div>
      <div className={style.imageWrapper}>
        <img height={98} width={98} className="lazy" data-src={feature_image} alt="" />
      </div>
    </Link>
  );
};
