import React from 'react';

import { IPostCard } from '../Card';
import { TrendingPostCard } from '../Card/Trending';

import style from './Trending.scss';

interface IProps {
  posts: {
    node: IPostCard;
  }[];
}

export const TrendingPosts = ({ posts }: IProps) => {
  return (
    <section className={style.wrapper}>
      {posts.map(({ node }, index) => (
        <TrendingPostCard key={index} post={node} index={index + 1} />
      ))}
    </section>
  );
};
