import React from 'react';

import Book from '../../images/Book';
import Calculator from '../../images/Calculator';
import Financial from '../../images/Financial';
import HowMuch from '../../images/HowMuch';

import style from './FinancialToolsDesktop.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IFinancialToolsDesktopProps {
  className?: string;
}

export const FinancialToolsDesktop = (props: IFinancialToolsDesktopProps) => {
  return (
    <div className={cn(style.financialToolsDesktop, props.className)}>
      <div className="container">
        <h2>
          <Financial className="d-sm-none" />
          Financial Tools
        </h2>
        <div className={style.links}>
          <Link to="/retirement-calculator/" className={style.base}>
            <div>
              <Calculator width={36} />
            </div>
            <h4>Retirement Calculator: How Much Do I Really Need to Retire?</h4>
          </Link>
          <Link to="/how-to-invest/" className={style.base}>
            <div>
              <HowMuch />
            </div>
            <h4>How to invest when you feel like you're behind</h4>
          </Link>
          <Link to="/millennial-retirement-plan/" className={style.base}>
            <div>
              <Book />
            </div>
            <h4>The Millennial Retirement Plan So Crazy It Just Might Work</h4>
          </Link>
        </div>
      </div>
    </div>
  );
};
