import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const TrendingIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      <path
        opacity="0.5"
        d="M9 15L15 9M15 9V13.5M15 9H10.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default React.memo(TrendingIcon);
